import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout 

import Dashboard from "@/views/main/Dashboard.vue";
import Message from "@/views/main/Message.vue";
import change_password from "@/views/main/change_password.vue";
import Tables from "@/views/main/Tables.vue";
import Maps from "@/views/main/Maps.vue";
import Employees from "@/views/main/Employees.vue";
import Payables from "@/views/main/Payables.vue";
import Payroll from "@/views/main/Payroll.vue";
import TimeSheet from "@/views/main/Timesheet.vue";
import Resources from "@/views/main/Resources.vue";
import ResourcesSubmission from "@/views/main/file_uploader.vue";
import HireEmployees from "@/views/main/hired_employees.vue";
import Reports from "@/views/main/Reports.vue";

// views for Auth layout  

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import forgot_password from "@/views/auth/forgot_password.vue";

// views without layouts

import Landing from "@/views/Landing.vue";
import Profile from "@/views/Profile.vue";
// import Index from "@/views/Index.vue";

// routes

const routes = [
  {
    path: "/admin",
    redirect: "/main/dashboard",
    component: Admin,
    children: [
      {
        path: "/main/dashboard",
        component: Dashboard,
        meta: { requiresAuth: true },
      },
      {
        path: "/main/message",
        component: Message,
        meta: { requiresAuth: true },
      },
      {
        path: "/main/resources",
        component: Resources,
        meta: { requiresAuth: true },
      },
      {
        path: "/main/reports",
        component: Reports,
        meta: { requiresAuth: true },
      },
      {
        path: "/main/hire_employees",
        component: HireEmployees,
        meta: { requiresAuth: true },
      },
      {
        path: "/main/change_password",
        component: change_password,
        meta: { requiresAuth: true },
      },
      {
        path: "/main/tables",
        component: Tables,
        meta: { requiresAuth: true },
      },
      {
        path: "/main/maps",
        component: Maps,
        meta: { requiresAuth: true },
      },
      {
        path: "/main/employees",
        component: Employees,
        meta: { requiresAuth: true },
      },
      {
        path: "/main/payables",
        component: Payables,
        meta: { requiresAuth: true },
      },
      {
        path: "/main/Resources_uploader",
        component: ResourcesSubmission,
        meta: { requiresAuth: true },
      },
      {
        path: "/main/timesheets",
        component: TimeSheet,
        meta: { requiresAuth: true },
      },
      {
        path: "/main/payroll",
        component: Payroll,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        component: Login,
        
      },
      {
        path: "/auth/register",
        component: Register,
      },
      {
        path: "/auth/forgot_password",
        component: forgot_password,
      },
    ],
  },
  {
    path: "/landing",
    component: Landing,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/",
    redirect: "/auth/login",
    component: Login,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const tokenExpiration = localStorage.getItem("tokenExpiration");
  const isSignInRoute = to.path === "/auth/login";
  const isRegisterRoute = to.path === "/auth/register";
  const isForgotPass = to.path === "/auth/forgot_password";
  // Check if the token exists and is not expired
  if (isSignInRoute ||isRegisterRoute || isForgotPass) {
    next();
  } else {
    // Check if the token exists and is not expired
    if (token && tokenExpiration && new Date().getTime() < tokenExpiration) {
      const new_expirationTime = new Date().getTime() + 10 * 60 * 60 * 1000;
      localStorage.setItem('tokenExpiration', new_expirationTime);
      next(); // Continue with the navigation
      
    } else {
      next("/sign-in"); // Redirect to the sign-in route
    }
  }
});


createApp(App).use(router).mount("#app");
