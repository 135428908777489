<template>
    <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
      >
        <div class="rounded-t bg-white mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">Change Password</h6>
          </div>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
          
            <div class="flex flex-wrap mt-5">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    New Password
                  </label>
                  <input
                  v-model="params_data.password"
                    type="password"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Confirm Password
                  </label>
                  <input
                  v-model="params_data.confirm_password"
                    type="password"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                  <span  v-if="params_data.password !== params_data.confirm_password && pass_not_match" class="text-red-500 text-sm"><small>Password did not matched.</small></span><br>
                  <span  v-if="params_data.password.length <= 7 && params_data.password.length >0"  class="text-red-500 text-sm"><small>Password invalid. Minimum of 8 Characters</small></span>
                </div>
              </div>
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                   
                  <button @click="send"
                        class="bg-blueGray-600 text-white text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                      >
                        Change Password
                      </button>
                </div>
              </div>
            
            </div>
        </form>
        </div>
      </div>
    </template>
    <script>
    import axios from 'axios';
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    
    export default {
      data() {
        return {
          params_data: {
            password: "",
            confirm_password: "",
            token:""
          },
          loading: false,
          pass_not_match:false,
        };
      },
      methods: {
        async send() {
            if(this.params_data.password !== this.params_data.confirm_password ) return this.pass_not_match =true;
          const token =  localStorage.getItem('token');
          this.params_data.token =token;
          try {
                const response = await Swal.fire({
                  title: 'Are you sure you want to send this message?',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, send it!',
                  allowOutsideClick: false,
                  showLoaderOnConfirm: true,
                  preConfirm: async () => {
                    try {
                      const result = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/change_password_client`, this.params_data);
    
                      if (result.data.status === 200) {
                        Swal.fire({ title: result.data.success, icon: 'success' });
                        this.params_data.password="";
                        this.params_data.confirm_password="";
                      } else {
                        Swal.fire({ title: result.data.success, icon: 'error' });
                      }
                    } catch (error) {
                      Swal.showValidationMessage(error.message);
                    }
                  },
                });
                console.log(response)
                
              } catch (error) {
                // Handle unexpected errors
                
                console.error(error);
              }
        },
      },
    };
    </script>
    
    