<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div class="text-right mt-6">
          <button @click="openModal"
            class="bg-lightBlue-600 text-white text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150">
            Hire Employee
          </button>
        </div>

        <div class="overflow-x-auto">
          <table class="w-full min-w-full divide-y divide-gray-200">
            <thead class="bg-blueGray-600">
              <tr>
                <th class="text-white px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  First Name
                </th>
                <th class="text-white px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Last Name
                </th>
                <th class="text-white px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  SP Number
                </th>
                <th class="text-white px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Email
                </th>
                <th class="text-white px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Phone Number
                </th>
                <th class="text-white px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Position
                </th>
                <th class="text-white px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Hired Date
                </th>
                <th class="text-white px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Separation Date
                </th>
                <th class="text-white px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="employee in sortedEmployees" :key="employee.id">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ employee.employee.first_name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {{ employee.employee.last_name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {{ employee.employee.SP_number }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {{ employee.employee.email }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {{ employee.employee.phone_number }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {{ employee.position }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {{ new Date(employee.hired_date).toLocaleDateString() }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {{ employee.separation_date ? new Date(employee.separation_date).toLocaleDateString() : 'N/A' }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button v-if="!employee.separation_date"
                    @click="terminateEmployee(employee.id)"
                    class="bg-red-500 text-white text-sm font-bold uppercase px-4 py-2 rounded shadow hover:bg-red-600 outline-none focus:outline-none mb-1">
                    Terminate
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hire-employee-modal :is-open="isModalOpen" @close="closeModal" />
    </div>
  </div>
</template>

<script>
import HireEmployeeModal from "@/components/Modal/HireEmployeeModal.vue";
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';

export default {
  components: {
    HireEmployeeModal,
  },
  data() {
    return {
      isModalOpen: false,
      employees_data: []
    }
  },
  computed: {
    sortedEmployees() {
      return [...this.employees_data].sort((a) => (a.separation_date ? 1 : -1));
    }
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.fetch_client_employees();
    },
    async fetch_client_employees() {
      const token = localStorage.getItem('token');

      const params_data = {
        token: token,
      };

      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_client_employee`, params_data);
        this.employees_data = response.data.data;
      } catch (error) {
        console.error("Backend error:", error);
      }
    },
    async terminateEmployee(id) {
      const token = localStorage.getItem('token');

      const params_data = {
        token: token,
        id: id,
      };

      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/terminate_employee`, params_data);
        if (response.data.status == 200) {
          Swal.fire({ title: response.data.message, icon: "success" });
          this.fetch_client_employees(); 
        } else {
          Swal.fire({ title: response.data.message, icon: "error" });
        }
      } catch (error) {
        console.error("Backend error:", error);
      }
    },
  },
  mounted() {
    this.fetch_client_employees();
  },
};
</script>

<style scoped>
/* Add your styling for the modal */
.modal-overlay {
  /* Overlay styles */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1040;
}

.modal-container {
  /* Modal styles */
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1050;
}

.modal-header {
  /* Header styles */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
}

.modal-header button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
</style>
