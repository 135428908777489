<template>
    <div v-if="isOpenTimeSheet" class="modal-overlay">
      <div class="modal-container">
        <div class="modal-header">
          <button @click="closeTimesheet" class="modal-close">&times;</button>
        </div>
        <div class="modal-body">
          
          
          <div class="rounded-t mb-0 px-4 py-6 bg-transparent">
                  <div class="flex flex-wrap items-center">
                    <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
            >
             
              <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                
                  <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                    Employee's Details
                  </h6>
                  <div class="flex flex-wrap">
                    <div class="w-full lg:w-6/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Employee's Name:
                        </label>
                        <input
                          type="text"
                         
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          :value="fullUserName" disabled
                        />
                      </div>
                    </div>
                    
                    
                  </div>

                  <hr class="mt-6 border-b-1 border-blueGray-300" />
                 
                    <form action="" class="flex items-center">
                        <div class="flex items-center">
                          <h6 class="text-blueGray-400 text-sm mt-3 mr-5 mb-6 font-bold uppercase">
                            From
                          </h6>
                          <input
                            class="text-black active:bg-emerald-200 text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="date"
                            name=""
                            id=""
                          />
                        </div>

                        <div class="flex items-center mx-4">
                          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            To
                          </h6>
                          <input
                            class="text-black active:bg-emerald-200 text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1  transition-all duration-150"
                            type="date"
                            name=""
                            id=""
                          />
                        </div>

                        <button
                          @click="openModal"
                          class="bg-emerald-600 text-white active:bg-emerald-200 text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                        >
                          View Timesheet
                        </button>
                      </form>

              </div>
            </div>
        </div>
    
           <table  class="items-center w-full  border-collapse">
            <thead class="bg-gray-200">
              <th>Date</th>
              <th>Time In</th>
              <th>Time Out</th>
              <th>Total Hours</th>
            </thead>
            <tbody>
              <tr>
                <td colspan="4" class="text-center">No data</td>
              </tr>
            </tbody>
           </table>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  export default{
    props : {
      isOpenTimeSheet: Boolean,
      timesheetData: Object,
    }, 
    methods:
    {
      closeTimesheet()
      {
        this.$emit('close_timesheet');
      }
    },
    data() {
    return {
      localTimesheetData: { ...this.timesheetData },
    };
  },
  watch: {
    timesheetData: {
      handler(newVal) {
        this.localTimesheetData = { ...newVal };
      },
      immediate: true,
    },
    localTimesheetData: {
        handler(newVal) {
          this.$emit('update:timesheetData', newVal);
        },
        deep: true,
      },
    },
    computed: {
    fullUserName() {
      return `${this.localTimesheetData.FirstName} ${this.localTimesheetData.LastName}`;
    },
    },
  }
  </script>
  
  <style scoped>
  /* Add your styling for the modal */
  .modal-overlay {
    /* Overlay styles */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1040;
  }
  
  .modal-container {
    /* Modal styles */
    width: 80%;
    height: 80%;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1050;
  }
  
  .modal-header {
    /* Header styles */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-header h3 {
    margin: 0;
  }
  
  .modal-header button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    
  }
  .modal-close {
    position: absolute;
    top: calc(10%); /* Adjust top and right values as needed */
    right: calc(11%);
    font-size: 40px !important;
  }

  </style>
  