<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-6/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <hr class="mb-6 border-b-1 border-blueGray-300" />
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                CREATE AN ACCOUNT
              </h6>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-blueGray-400 text-center mb-3 font-bold">
            </div>
            <form @submit.prevent="submitForm">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  First Name
                  <span  class="text-red-500">*</span>
                </label>
                
                <input
                  v-model="formData.first_name"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="First Name"
                  name="first_name"
                />
                <span v-if="!formData.first_name.trim() && validation_fail" class="text-red-500 text-sm">First Name is required</span>
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Last Name
                  <span  class="text-red-500">*</span>
                </label>
                <input
                  v-model="formData.last_name"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Last Name"
                  name="last_name"
                />
                <span v-if="!formData.last_name.trim() && validation_fail" class="text-red-500 text-sm">Last Name is required</span>
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  UCI
                  <span  class="text-red-500">*</span>
                </label>
                <input
                  v-model="formData.ss_number"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="UCI Number"
                  name="ss_number"
                />
                <span v-if="!formData.ss_number.trim() && validation_fail" class="text-red-500 text-sm">UCI number is required</span>
                <span v-if="formData.ss_number.length < 7 && formData.ss_number.trim().length > 0" class="text-red-500 text-sm">UCI number must be atleast 7 characters alphanumeric</span>
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Address
                  <span  class="text-red-500">*</span>
                </label>
                <input
                  v-model="formData.address"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Address"
                  name="address"
                />
                <span v-if="!formData.address.trim() && validation_fail" class="text-red-500 text-sm">Address is required</span>
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  City
                  <span  class="text-red-500">*</span>
                </label>
                <input
                  v-model="formData.city"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="City"
                  name="city"
                />
                <span v-if="!formData.city.trim() && validation_fail" class="text-red-500 text-sm">City is required</span>
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  State
                  <span  class="text-red-500">*</span>
                </label>
                <input
                  v-model="formData.state"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="State"
                  name="state"
                />
                <span v-if="!formData.state.trim() && validation_fail" class="text-red-500 text-sm">State is required</span>
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Zip Code
                  <span  class="text-red-500">*</span>
                </label>
                <input
                  v-model="formData.zip_code"
                  type="number"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Zip Code"
                  name="zip_code"
                />
                <span v-if="!formData.zip_code.trim() && validation_fail" class="text-red-500 text-sm">Zip Code is required</span>
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Contact Number
                  <span  class="text-red-500">*</span>
                </label>
                <input
                  v-model="formData.contact_number"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Name"
                  name="contact_number"
                />
                <span v-if="!formData.contact_number.trim() && validation_fail" class="text-red-500 text-sm">Contact Number is required</span>
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                  <span  class="text-red-500">*</span>
                </label>
                <input
                  v-model="formData.email"
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                  name="email"
                />
                <span v-if="!formData.email.trim() && validation_fail" class="text-red-500 text-sm">Email is required</span>
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Password
                  <span  class="text-red-500">*</span>
                </label>
                <input
                  v-model="formData.password"
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                  name="password"
                />
                <span v-if="!formData.password.trim() && validation_fail" class="text-red-500 text-sm">Password is required</span>
                <span v-if="formData.password.length<8 && formData.password.trim()" class="text-red-500 text-sm">Password should contain minimum of 8 characters</span>
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Retype Password
                  <span  class="text-red-500">*</span>
                </label>
                <input
                  v-model="formData.password_confirm"
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder=" Retype Password"
                  name="password_confirm"
                />
                <span v-if="formData.password !== formData.password_confirm && formData.password_confirm.length>0" class="text-red-500 text-sm">Password did not match.</span>
              </div>

              <div>
                <label class="inline-flex items-center cursor-pointer">
                  
                  <input
                  v-model="agreeToPrivacyPolicy"
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    I agree with the
                    <a href="javascript:void(0)" class="text-emerald-500">
                      Privacy Policy
                    </a>
                  </span>
                  <span  class="text-red-500">*</span>
                </label>
                
   
              </div>
              <span v-if="!agreeToPrivacyPolicy && validation_fail" class="text-red-500 text-sm">Please agree to the Privacy Policy</span>
              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Create Account
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>
<script>
import axios from "axios";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';

export default {
  data() {
    return {
      formData: {
        first_name:"",
        last_name:"",
        ss_number:"",
        address:"",
        city:"",
        state:"",
        zip_code:"",
        contact_number:"",
        email:"",
        password:"",
        password_confirm:"",
      },
      agreeToPrivacyPolicy: false,
      validation_fail: false,
    };
  },
  computed: {
    formIsValid() {
      return (
        this.agreeToPrivacyPolicy &&
        this.formData.first_name.trim() !== "" &&
        this.formData.last_name.trim() !== "" &&
        this.formData.ss_number.trim() !== "" &&
        this.formData.ss_number.trim().length > 6 &&
        this.formData.address.trim() !== "" &&
        this.formData.city.trim() !== "" &&
        this.formData.state.trim() !== "" &&
        this.formData.zip_code.trim() !== "" &&
        this.formData.contact_number.trim() !== "" &&
        this.formData.email.trim() !== "" &&
        this.formData.password.length >= 8
      );
    },
  },
  methods: {
    async submitForm() {
      if (this.formIsValid) {
        const loadingPopup = Swal.fire({
          title: 'Loading...',
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
          onBeforeOpen: () => Swal.showLoading(),
        });
        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/create_client`, this.formData);
          loadingPopup.close();
          
          if (response.data.status === 200) {
            Swal.fire({ title: "Registration was submitted", icon: "success",})
            .then(() => {this.$router.push({ path: '/auth/login' });
            });
          } else {
            Swal.fire({ title: response.data.errors, icon: "error" });
          }
        } catch (error) {
          const errorData = Object.values(error.response.data.errors).flat().join(" ");
          Swal.fire({ title: errorData, icon: "error" });
        }
      } else {
        this.validation_fail = true;
      }
    },
  },
};
</script>

