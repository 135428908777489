<template>
    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-4/12 px-4">
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
          >
         
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0 mt-4">
              <div class="text-blueGray-400 text-center mb-3 font-bold">
                <small>Enter your email addres</small>
              </div>
              <span class="text-red-500 text-sm"><small>Once you click submit, an email will be sent to you containing your new password.</small></span>
              <form @submit.prevent="submit">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email
                  </label>
                  <input
                    v-model="email"
                    type="email"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                  />
                </div>
  
               
  
                <div class="text-center mt-6">
                  <button
                    class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
        </div>
        </div>
      </div>

    </div>
  </template>
  <script>
    import axios from "axios";
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import 'sweetalert2/dist/sweetalert2.min.css';
    export default 
    {
        data() 
            {
                return {
                email: ""
                };
            },
        methods:
        {
            async submit()
            {
                try {
                    const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/reset_password_client`,{email: this.email});
                 
                    
                    if (response.data.status === 200) {
                        Swal.fire({ title: "Check your email for your new password", icon: "success",})
                        .then(() => {this.$router.push({ path: '/auth/login' });
                        });
                    } else {
                        Swal.fire({ title: response.data.errors, icon: "error" });
                    }
                    } catch (error) {
                    const errorData = Object.values(error.response.data.errors).flat().join(" ");
                    Swal.fire({ title: errorData, icon: "error" });
                    }

            }
        }
    
   
    }
 
  </script>
  