<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-200"
    
    >
      <div class="rounded-t mb-0 px-4 py-3 border-0 bg-teal-400">
        <div class="flex flex-wrap items-center ">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3
              class="font-semibold text-lg text-black "
              
            >
            Timesheets
            </h3>
          </div>
          <div class="flex items-center justify-between mt-4">
            <button type="button" class="btn_export_timesheet text-white text-sm font-bold uppercase px-4 py-2 rounded shadow outline-none focus:outline-none mr-2" @click="exportToPDF">
              Export to PDF
            </button>
            <button type="button" class="btn_export_timesheet text-white text-sm font-bold uppercase px-4 py-2 rounded shadow outline-none focus:outline-none" @click="exportToExcel">
              Export to Excel
            </button>
          </div>
        </div>
        <div class="flex flex-wrap items-center ">
          
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
           <select name="filter_employees" id="employee_filter" v-model="f_employee" @change="apply_filter" >
            <option value="all">All</option>
            <option v-for="employee in employees" :key="employee" :value="employee.id" >
            {{ employee.first_name }} {{ employee.last_name }}
            </option>
           </select>

         

           <select name="filter_month" id="filter_month" v-model="f_month" @change="apply_filter" class="ml-3">
            <option v-for="month in months" :key="month" :value="month" >
            {{ month }}
            </option>
           </select>

           <select name="filter_year" id="filter_year" v-model="f_year" @change="apply_filter" class="ml-3">
            <option v-for="year in years" :key="year" :value="year" >
            {{ year }}
            </option>
           </select>
          </div>
        </div>
      </div>
      <div class="block w-full overflow-x-auto xport_pdf">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
                Time In
              </th>
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
                Time Out
              </th>
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
               Total Hours
              </th>
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-600 "
                
                >Service Code
              </th>
  
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-600 "
                
                >Specification
              </th>
            <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-600 "
                
              >Employee
            </th>
            <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-600 "
                
              >Status
            </th>
            <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-600 "
                
              >Action
            </th>
             
            </tr>
             
          </thead>
          <tbody>
            <tr v-for="timesheet in filtered_sheets" :key="timesheet.id" class="border-b border-gray-300" >
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                {{ timesheet.start_date }} {{ timesheet.start_time }}
              </td>
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                {{ timesheet.end_date }} {{ timesheet.end_time }}
              </td>
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                {{ timesheet.total_hours }}
              </td>
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                {{ timesheet.service_code }}
              </td>
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                {{ timesheet.specification?? "No specifications" }}
              </td>
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                {{ timesheet.employee.first_name }} {{ timesheet.employee.last_name }} -({{ timesheet.employee.SP_number }})
              </td>
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                <div v-if="timesheet.status == 0" >
                  <span class="text-xs font-weight-bold">Pending</span>
                </div>
                <div v-else-if="timesheet.status == 1" >
                  <span class="text-xs font-weight-bold">Approved</span>
                </div>
                <div v-else>
                  <span class="text-xs font-weight-bold">Declined</span>
                </div>
              </td>
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                <div v-if="timesheet.status == 0" >
                  <button type="button"   class="bg-lightBlue-600 text-white text-sm font-bold uppercase px-2 py-2 rounded shadow outline-none focus:outline-none mr-1 mb-1  ease-linear transition-all duration-150" @click="declined(timesheet.id, '1')">Approved</button>
                  <button type="button"   class="bg-red-400 text-white text-sm font-bold uppercase px-2 py-2 rounded shadow outline-none focus:outline-none mr-1 mb-1  ease-linear transition-all duration-150" @click="declined(timesheet.id, '-1')">Decline</button>
                </div>
                <div v-else-if="timesheet.status == 1" >
                  <span class="text-xs font-weight-bold">Approved</span>
                </div>
                <div v-else>
                  <span class="text-xs font-weight-bold" >Declined</span>
                </div>
              </td>

             
            </tr>
           
          </tbody>
        </table>
      </div>
     
    </div>
  </template>
  <script>

import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import jsPDF from 'jspdf';
import $ from 'jquery';
import html2canvas from 'html2canvas';
  export default {
    data()
        {
          const currentYear = new Date().getFullYear();
          const months = [
              'January','February','March','April','May','June','July','August','September','November','December'
            ];
          const currentMonth = new Date().getMonth();
          const year_padding1 = currentYear - 5;
          const year_padding2 = currentYear + 5;

          const years = [];

          for (let i = year_padding1; i < year_padding2; i++) {
            years.push(i);
          }


           return {
            timesheets:[],
            employees:[],
            f_employee:"all",
            filtered_sheets:[],
            years:years,
            f_year:currentYear,
            f_month:months[currentMonth],
            months:months
           
           }
        },
    methods: {
      async fetch_timesheets()
      {
        try {
                const token = localStorage.getItem('token');
                const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/timesheets_client`,{}, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'multipart/form-data',
                },
              });
                this.timesheets = response.data.data; 
                this.filtered_sheets = response.data.data; 
               
              } catch (error) {
                // Handle errors
                console.error("Backend error:", error);
              }
      },
      async fetch_employees()
      {
        const token = localStorage.getItem('token');

          const params_data = {
            token: token,

          };
        try {
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_employees`, params_data);
            this.employees = response.data; 
            
          } catch (error) {
            
            console.error("Backend error:", error);
          }
      },
      async declined(id, status) {
        var message = "Are you sure you want to decline this?";
        var icon = "warning";
        if (status == "1") {
          message = "Are you sure you want to approve this timesheet?";
          icon = "info";
        }

        Swal.fire({
          title: message,
          text: "You won't be able to revert this!",
          icon: icon,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Proceed",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const token = localStorage.getItem('token');
              const response = await (async () => {
                return axios.post(
                  `${process.env.VUE_APP_BACKEND_DOMAIN}/api/decline_timesheet`,
                  { time_sheet_id: id, status },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'multipart/form-data',
                    },
                  }
                );
              })();
              
              if (response.status == 201) {
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "timesheet approved",
                  showConfirmButton: false,
                  timer: 1500
                });
                this.fetch_timesheets();
              } else {
                Swal.fire({
                  position: "bottom-end",
                  icon: "error",
                  title: response.message,
                  showConfirmButton: false,
                  timer: 1500
                });
              }
            } catch (error) {
              // Handle errors
              console.error("Backend error:", error);
            }
          }
        });
      },
      apply_filter()
      {
         if(this.f_employee=="all")
        {
          this.filtered_sheets = this.timesheets.filter((timesheet)=>{
            const startYear = new Date(timesheet.start_date).getFullYear();
            const startMonth = new Date(timesheet.start_date).getMonth();
            return (
              startYear == this.f_year &&
              this.months[startMonth] ==this.f_month
              );
          });
        }
        if(this.f_employee!="all")
        {
          this.filtered_sheets = this.timesheets.filter((timesheet) => {
            const startYear = new Date(timesheet.start_date).getFullYear();
            const startMonth = new Date(timesheet.start_date).getMonth();
              return (
                timesheet.employee_id == this.f_employee &&
                startYear == this.f_year &&
                this.months[startMonth] ==this.f_month
              );
            });
          }
       
        },
      exportToPDF() {
        const month_entry = $('#filter_month').val();
        const year_entry = $('#filter_year').val();
        const filename = 'timesheets_'+month_entry+'_'+year_entry+'.pdf';
        const pdf = new jsPDF('p', 'pt', 'a4');

        // Define the HTML element to be captured
        const element = document.querySelector('.xport_pdf');

        // Calculate page dimensions (A4 size: 595.28 x 841.89 points)
        const pdfWidth = 595.28;
        const pdfHeight = 841.89;

        // Calculate scale factor to fit content within PDF page
        const widthRatio = pdfWidth / element.offsetWidth;
        const heightRatio = pdfHeight / element.offsetHeight;
        const scaleFactor = Math.min(widthRatio, heightRatio);

        // Calculate scaled dimensions
        const scaledWidth = element.offsetWidth * scaleFactor;
        const scaledHeight = element.offsetHeight * scaleFactor;

        html2canvas(element, {
          useCORS: true,
          logging: true,
          allowTaint: false,
          width: scaledWidth,
          height: scaledHeight,
          scale: scaleFactor
        }).then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const imgWidth = pdfWidth; // A4 width in points
          const imgHeight = canvas.height * imgWidth / canvas.width;

          pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
          pdf.save(filename);
        }).catch(error => {
          console.error('Error generating PDF:', error);
        });
    },

    exportToExcel() {
      const month_entry = $('#filter_month').val();
      const year_entry = $('#filter_year').val();
      const filename = 'timesheets_' + month_entry + '_' + year_entry + '.csv';
      const csvContent = this.convertToCSV(this.filtered_sheets);

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // For IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    convertToCSV(data) {
      // Define headers and adjust column widths
      const headers = ['Time In', 'Time Out', 'Total Hours', 'Service Code', 'Specification', 'Status'];

      // Convert data to CSV format
      const csv = data.map(row => {
        return [
          `${row.start_date} ${row.start_time}`,
          `${row.end_date} ${row.end_time}`,
          `${row.total_hours}`,
          `${row.service_code}`,
          `${row.specification ?? "No specifications"}`,
          `${row.status === 0 ? 'Pending' : row.status === 1 ? 'Approved' : 'Declined'}`
        ].join(',');
      }).join('\n');

      return `${headers.join(',')}\n${csv}`;
    }, 

    },
    mounted() {
          this.fetch_timesheets();
          this.fetch_employees();
          this.apply_filter();
    }
  };
  </script>
  <style scoped>
  .btn_export_timesheet{
    background-color:#059669 !important;
  }
</style>