<template>
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-200"
    
    >
      <div class="rounded-t mb-0 px-4 py-3 border-0 bg-teal-400">
        <div class="flex flex-wrap items-center ">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3
              class="font-semibold text-lg text-black "
              
            >
              Payables List
            </h3>
          </div>
          <div class="text-center mt-6">
                  <button @click="openModal"
                    class="bg-lightBlue-600 text-white text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    
                  >
                    Upload Invoice
                  </button>
                  
                </div>
        </div>
      </div>
      <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
                Date Uploaded
              </th>
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
                Invoices
              </th>
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
               Description
              </th>
  
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-600 "
                
              >Reciept
            </th>
            <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-600 "
                
              >Action
            </th>
             
            </tr>
             
          </thead>
          <tbody>
            <tr v-for="payable in payables" :key="payable.id" class="border-b border-gray-300" >
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                {{ formatToYmd(payable.upload_date) }}
              </td>
              <td class="px-6 align-middle   py-3 text-sm whitespace-nowrap font-semibold text-left">
                <div v-for="file_data in payable.files" :key="file_data">
                <button @click="openFileSwal(file_data, getFileNameFromUrl(file_data))" 
                class="bg-teal-200  m-2"
                >
                  {{ getFileNameFromUrl(file_data)}}
                  
                </button>
              </div>
              </td>
              <td class="px-6 align-middle   py-3 text-sm whitespace-nowrap font-semibold text-left">
                {{ payable.description }}
              </td>
              <td class="px-6 align-middle   py-3 text-sm whitespace-nowrap font-semibold text-left">
                <div v-if="payable.receipt && payable.receipt.length > 0" >
                  <div v-for="file_data_r in payable.receipt" :key="file_data_r">
                    <button @click="openFileSwal(file_data_r, getFileNameFromUrl(file_data_r))" class="bg-teal-200  m-1">
                      {{ getFileNameFromUrl(file_data_r) }}
                    </button>
                  </div>
                </div>
                
                <div v-else>
                
                  No receipt available.
                </div>
                
              </td>
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
              </td>
            </tr>
           
          </tbody>
        </table>
      </div>
      <create-payables :is-open="isModalOpen" @close="closeModal" @refresh-table="refresh_emplyoees" />
    </div>
  </template>
  <script>
   import CreatePayables from "@/components/Modal/CreatePayablesModal.vue";
   import axios from 'axios';
   import Swal from 'sweetalert2/dist/sweetalert2.js';
   import 'sweetalert2/dist/sweetalert2.min.css';
   import $ from 'jquery';
   export default {
        data()
        {
           return {
            isModalOpen: false,
            payables:[]
           }
        },
        components: {
            CreatePayables,
        },
        methods: {
          getFileNameFromUrl(url) {
            // Extracts the file name from a URL
            const parts = url.split('/');
            return parts[parts.length - 1];
          },
            openModal() {
            this.isModalOpen = true;
            },
            closeModal() {
              this.fetchPayables();
            this.isModalOpen = false;
            },
            async fetchPayables()
            {
              const token = localStorage.getItem('token');

              const params_data = {
                token: token,

              };

              try {
                const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_payable`, params_data);
                this.payables = response.data.data; 
               
              } catch (error) {
                // Handle errors
                console.error("Backend error:", error);
              }
            },
            formatToYmd(dateTimeString) {
              const date = new Date(dateTimeString);
              const year = date.getUTCFullYear();
              const month = String(date.getUTCMonth() + 1).padStart(2, '0');
              const day = String(date.getUTCDate()).padStart(2, '0');
              return `${year}-${month}-${day}`;
            },
            openFileSwal(file_data, filename) {
                Swal.fire({
                  title: filename,
                  html: this.getHtmlContent(file_data, filename),
                  showCloseButton: true,
                  showCancelButton: false,
                  showConfirmButton:false,
                  focusConfirm: false,
                });
            },
            getHtmlContent(file_data, file_name) {
              let content = '';
              // var encodedFileName = encodeURIComponent(file_name);
              var btn_class ="bg-lightBlue-600 text-white text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 w-half ease-linear transition-all duration-150 btn_download";
              if (this.isImage(file_name)) 
              {
                content += `<img src="${file_data}" style="max-width: 100%; max-height: 100%;">`;
                content += `<br><button class="${btn_class}" data-url="${file_data}" data-filename="${file_name}">Download</button>`;
              } 
              else if (this.isExcel(file_name)) 
              {
                content += `Please Download The file to view: <a class="bg-teal-500" href="${file_data}" download="${file_name}">Click here to download the file</a>`;
              } 
              else 
              {
                content += "Unsupported file type";
              }

              return content;
            },
            isImage(fileName) {
              const imageExtensions = [".png", ".jpg", ".jpeg", ".gif", ".bmp", ".pdf"];
              return imageExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
            },
            isExcel(fileName) {
              // Check if the MIME type indicates an Excel file
              return fileName.toLowerCase().endsWith(".xlsx");
            },
        },
        mounted() {
          this.fetchPayables();
        },
   }
   $(document).on('click', '.btn_download', function(){
        var url = $(this).data('url');
        var filename = $(this).data('filename');
        getImageBlobFromS3(url,filename);
    })
    function getImageBlobFromS3(url,fileName) 
    {
        $.ajax({
            url: url,
            type: 'GET',
            xhrFields: {
                responseType: 'blob' 
            },
            success: function(data) {
                downloadBlob(data,fileName);
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.error('Error fetching image:', errorThrown);
            }
        });
        function downloadBlob(blob, fileName) 
            {
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            document.body.appendChild(a);
            a.click(); 
            document.body.removeChild(a);
            }   
    }
  </script>