<template>
    <div class="flex flex-wrap">
      <div class="w-full px-4">
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
        >
        <table>
            <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
                Report Title
            </th>
            <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
              Report Description
            </th>
            <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
              Report Date
            </th>
            <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
                Actions
            </th>
            <tr v-for="resource in resources" :key="resource.id" class="border-b border-gray-300" >
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                {{ resource.report_type }}
              </td>
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                {{ resource.description }}
              </td>
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                {{ resource.report_date }}
              </td>
              <td class="px-6 align-middle">
                <button @click="getImageBlobFromS3(resource.report_file, resource.report_type)" class="bg-teal-200 text-black active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1  ease-linear transition-all duration-150">
                     Download
                </button>
              </td>
              
            </tr>
        </table>
        
        </div>
      </div>
    </div>
  </template>
  <script>
import axios from 'axios';
  export default {
    data()
        {
           return {
            resources:[]
           }
        },
    methods:
    {
        async fetchResources()
            {
              const token = localStorage.getItem('token');

              const params_data = {
                token: token,

              };

              try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_reports_client`, {
                params: params_data  
              });
                this.resources = response.data.data; 
                console.log(this.resources);
               
              } catch (error) {
                // Handle errors
                console.error("Backend error:", error);
              }
            },

            getFileNameFromUrl(url) {
                const parts = url.split('/');
                return parts[parts.length - 1];
            },
            async getImageBlobFromS3(url, fileName) {
            try {
                const response = await axios.get(url, { responseType: 'blob' });
                this.downloadBlob(response.data, fileName);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        },
        downloadBlob(blob, fileName) {
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            }
        
    },
    mounted() {
          this.fetchResources();
        },
  };
  </script>
  