<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-200"
    
    >
      <div class="rounded-t mb-0 px-4 py-3 border-0 bg-teal-400">
        <div class="flex flex-wrap items-center ">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3
              class="font-semibold text-lg text-black "
              
            >
            Payroll Sheets
            </h3>
          </div>
        </div>
        <div class="flex flex-wrap items-center ">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">

           <select name="filter_month" id="filter_month" v-model="f_month" @change="apply_filter" class="ml-3">
            <option v-for="month in months" :key="month" :value="month" >
            {{ month }}
            </option>
           </select>

           <select name="filter_year" id="filter_year" v-model="f_year" @change="apply_filter" class="ml-3">
            <option v-for="year in years" :key="year" :value="year" >
            {{ year }}
            </option>
           </select>
          </div>
        </div>
      </div>
      <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
                Payroll Start
              </th>
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
              Payroll End
              </th>
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
               Payroll File
              </th>
  
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-600 "
                
              >Time Created
            </th>
            <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-600 "
                
              >Employee Number
            </th>
    
            <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-600 "
                
              >Action
            </th>
             
            </tr>
             
          </thead>
          <tbody>
            <tr v-for="payroll in payroll_data" :key="payroll.id" class="border-b border-gray-300" >
             <td
             class="px-6 align-middle   py-3 text-sm whitespace-nowrap font-semibold text-left"
             >
             {{ payroll.payroll_start }}
            </td>
             <td 
             class="px-6 align-middle   py-3 text-sm whitespace-nowrap font-semibold text-left"
             >
             {{ payroll.payroll_end }}
            </td>
            <td 
             class="px-6 align-middle   py-3 text-sm whitespace-nowrap font-semibold text-left"
             >
             <span v-for="files in payroll.payroll_file_content" :key="files">
              {{ extractFileName(files) }} <br>
             </span>
            
            </td>
             <td 
             class="px-6 align-middle   py-3 text-sm whitespace-nowrap font-semibold text-left"
             >
             {{  new Date(payroll.created_at).toLocaleString() }}

            </td>
             <td 
             class="px-6 align-middle   py-3 text-sm whitespace-nowrap font-semibold text-left"
             >
             {{ payroll.employee.SP_number }}</td>
             <td 
             class="px-6 align-middle   py-3 text-sm whitespace-nowrap font-semibold text-left"
             >
              <button 
              class="bg-lightBlue-600 text-white text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              @click="downloadFiles(payroll.payroll_file_content)"
              >
              Downlaod
             </button></td>
            </tr>
           
          </tbody>
        </table>
      </div>
     
    </div>
  </template>
  <script>

import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';

  export default {
    data()
        {
          const currentYear = new Date().getFullYear();
          const months = [
              'January','February','March','April','May','June','July','August','September','November','December'
            ];
          const currentMonth = new Date().getMonth();
          const year_padding1 = currentYear - 5;
          const year_padding2 = currentYear + 5;

          const years = [];

          for (let i = year_padding1; i < year_padding2; i++) {
            years.push(i);
          }


           return {
            payroll:[],
            payroll_data:[],
            years:years,
            f_year:currentYear,
            f_month:months[currentMonth],
            months:months
           
           }
        },
    methods: {
      getFileNameFromUrl(url) {
      const parts = url.split('/');
      return parts[parts.length - 1];
    },
      async fetch_payroll()
      {
        try {
                const token = localStorage.getItem('token');
                const data_toke = token.split("$");
                const form_data = {
                  client_id:data_toke[1]

                }
                const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_payroll_api`,form_data, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'multipart/form-data',
                },
              });
                this.payroll = response.data.data; 
                this.payroll_data = response.data.data; 
               
              } catch (error) {
                // Handle errors
                console.error("Backend error:", error);
              }
      },
      formatCreatedAt(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
      });
    },
    extractFileName(filePath) {
      let fileName = filePath.substring(filePath.lastIndexOf('/') + 1);
        let extension = '';
        if (fileName.includes('.')) {
          extension = fileName.substring(fileName.lastIndexOf('.'));
          fileName = fileName.substring(0, fileName.lastIndexOf('.'));
        }
        return fileName.length > 20 ? fileName.substring(0, 20) + extension : fileName + extension;
      
    },
  
      
    apply_filter() {
      this.payroll_data = this.payroll.filter((payroll) => {
        const startYear = new Date(payroll.payroll_start).getFullYear();
        const startMonth = new Date(payroll.payroll_end).getMonth();
        return (
          startYear == this.f_year &&
          startMonth == this.months.indexOf(this.f_month)
        );
      });
    },
    downloadFiles(payrollFileContent) {
        // Show loading alert
        Swal.fire({
          title: 'Downloading Files',
          text: 'Please wait while the files are being downloaded...',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          willOpen: () => {
            Swal.showLoading();
          }
        });

        // Download files
        const promises = payrollFileContent.map(aws_url => {
          return new Promise((resolve, reject) => {
            fetch(aws_url)
              .then(response => response.blob())
              .then(blob => {
                const a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                const fileName = aws_url.substring(aws_url.lastIndexOf('/') + 1);
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                resolve(); // Resolve the promise when download is successful
              })
              .catch(error => {
                console.error('Error downloading file:', error);
                reject(error); // Reject the promise when download fails
              });
          });
        });

        // Wait for all downloads to complete
        Promise.all(promises)
          .then(() => {
            // Hide loading alert and show success message
            Swal.fire({
              icon: 'success',
              title: 'Download Completed',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(() => {
            // Hide loading alert and show error message
            Swal.fire({
              icon: 'error',
              title: 'Download Error',
              text: 'An error occurred while downloading files.',
              showConfirmButton: false,
              timer: 1500
            });
          });
      },
    },
    mounted() {
          this.fetch_payroll();
          this.apply_filter();
    }
  };
  </script>