<template>
    <div v-if="isOpen" class="modal-overlay">
      <div class="modal-container">
        <div class="modal-header">
          <h3>{{ title }}</h3>
          <button @click="closeModal">&times;</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="relative w-full mb-3">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Employee's First Name
                  <span class="text-red-500">*</span>
                </label>
                <input
                  v-model="formData.first_name"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="First Name"
                  name="first_name"
                />
                <span v-if="!formData.first_name.trim() && validation_fail" class="text-red-500 text-sm">First Name is required</span>
              </div>
  
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Employee's Last Name
                  <span class="text-red-500">*</span>
                </label>
                <input
                  v-model="formData.last_name"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Last Name"
                  name="last_name"
                />
                <span v-if="!formData.last_name.trim() && validation_fail" class="text-red-500 text-sm">Last Name is required</span>
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Employee's Email
                  <span class="text-red-500">*</span>
                </label>
                <input
                  v-model="formData.email"
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email Address"
                  name="email"
                />
                <span v-if="!formData.email.trim() && validation_fail" class="text-red-500 text-sm">Email is required</span>
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Employee's Phone Number
                  <span class="text-red-500">*</span>
                </label>
                <input
                  v-model="formData.phone_number"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Phone Number"
                  name="phone_number"
                />
                <span v-if="!formData.phone_number.trim() && validation_fail" class="text-red-500 text-sm">Phone Number is required</span>
              </div>
  
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Upload Documents
                  <span class="text-red-500">*</span>
                </label>
            </div>
            
            <input
               
                  type="file"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Last Name" @change="handleFileChange"
                  name="last_name[]" multiple
                />
                <span v-if="formData.files.length<=0 && validation_fail" class="text-red-500 text-sm">PLease upload a file</span>
              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import 'sweetalert2/dist/sweetalert2.min.css';
  export default {
    data() {
      return {
        formData: {
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
          files: [], 
        },
        agreeToPrivacyPolicy: false,
        validation_fail: false,
      };
    },
    props: {
      isOpen: Boolean,
      title: String,
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      handleFileChange(event) {
       
        this.formData.files = event.target.files;
      },
      async submitForm() {
        if (this.formIsValid) 
        {
            try {
      
          const token = localStorage.getItem('token');
  
     
          const formData = new FormData();
  
          formData.append('first_name', this.formData.first_name);
          formData.append('last_name', this.formData.last_name);
          formData.append('email', this.formData.email);
          formData.append('phone_number', this.formData.phone_number);
  
    
          for (let i = 0; i < this.formData.files.length; i++) {
            formData.append('files_upload[]', this.formData.files[i]);
          }
  
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/add_employee`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          });
  
          if(response.data.status==201)
          {
            this.$emit('close');
            this.$emit("refresh-table");
            Swal.fire({ title: "Employee was added", icon: "success",})
          }
          else
          {
            Swal.fire({ title: "Error Processing your request.", icon: "error",})
          }
        } catch (error) {
            const errorData = Object.values(error.response.data.errors).flat().join(" ");
          Swal.fire({ title: errorData, icon: "error" });
        }
        }
        else
        {
            this.validation_fail =true;
        }

      },
    },
    computed: {
    formIsValid() {
      return (
        this.formData.first_name.trim() !== "" &&
        this.formData.last_name.trim() !== "" &&
        this.formData.email.trim() !== "" &&
        this.formData.phone_number.trim() !== "" &&
        this.formData.files.length >0
      );
    },
  },
  };
  </script>
  
  <style scoped>
  /* Add your styling for the modal */
  .modal-overlay {
    /* Overlay styles */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1040;
  }
  
  .modal-container {
    /* Modal styles */
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1050;
  }
  
  .modal-header {
    /* Header styles */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-header h3 {
    margin: 0;
  }
  
  .modal-header button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  </style>
  