<template>
<div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Send Us a Message</h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Content
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Subject
              </label>
              <input
              v-model="params_data.subject"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
 
      
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Message
              </label>
              <textarea
              v-model="params_data.message"
                type="text"
                class="form-control border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Please specify your concern here."
              >
              </textarea
              >
            </div>
          </div>
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Message
              </label>
              <button @click="send"
                    class="bg-emerald-400 text-white text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Send
                  </button>
            </div>
          </div>
        
        </div>
    </form>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export default {
  data() {
    return {
      params_data: {
        subject: "",
        message: ""
      },
      loading: false, // Add loading state
    };
  },
  methods: {
    async send() {
      const token =  localStorage.getItem('token');
      try {
            const response = await Swal.fire({
              title: 'Are you sure you want to send this message?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, send it!',
              allowOutsideClick: false,
              showLoaderOnConfirm: true,
              preConfirm: async () => {
                try {
                  const result = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/send_email_cli`, this.params_data, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'multipart/form-data',
                    },
                  });

                  if (result.data.status === 200) {
                    return result.data;
                  } else {
                    throw new Error(result.data.message || 'Failed to send email');
                  }
                } catch (error) {
                  Swal.showValidationMessage(error.message);
                }
              },
            });

            if (response.value) {
              Swal.fire({ title: response.value.message, icon: 'success' });
              setTimeout(() => {
               
                location.reload();
              }, 2000);
            } else if (response.dismiss === Swal.DismissReason.cancel) {
              console.log('Email sending cancelled');
            }
          } catch (error) {
            // Handle unexpected errors
            console.error(error);
          }
    },
  },
};
</script>

