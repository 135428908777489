<template>
    <div v-if="isOpen" class="modal-overlay">
      <div class="modal-container">
        <div class="modal-header">
          <h3>{{ title }}</h3>
          <button @click="closeModal">&times;</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="relative w-full mb-3">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Description
                  <span class="text-red-500">*</span>
                </label>
            
  
                <span v-if="!formData.description.trim() && validation_fail" class="text-red-500 text-sm">description is required</span>
              </div>
  
              <div class="relative w-full mb-3">
                <textarea
                v-model="formData.description"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Description"
                  name="description"
                >

                </textarea>
               
              </div>
  
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Upload Documents
                  <span class="text-red-500">*</span>
                </label>
            </div>
            <span v-if="formData.files.length<=0 && validation_fail" class="text-red-500 text-sm">PLease upload a file</span>
            <input
               
                  type="file"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Last Name" @change="handleFileChange"
                  name="files[]" multiple
                />
                
              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import 'sweetalert2/dist/sweetalert2.min.css';
  export default {
    data() {
      return {
        formData: {
          description: '',
          files: [],
          token: ''
        },
        agreeToPrivacyPolicy: false,
        validation_fail: false,
        employees: Object
      };
    },
    props: {
      isOpen: Boolean,
      title: String,
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      handleFileChange(event) {
       
        this.formData.files = event.target.files;
      },
      async submitForm() {
        if (this.formIsValid) 
        {
          const token = localStorage.getItem('token');
          this.formData.token = token;
          Swal.fire({
          title: 'Uploading...',
          text: 'Please wait while the file is being uploaded.',
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          }
        });
          try {
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/upload_files`, this.formData, {
              headers: {
              'Content-Type': 'multipart/form-data',
            },
            });
            console.log( response.data);
            
            if(response.data.status==200)
            {
              Swal.fire({ title: response.data.message, icon: "success" });
              this.$emit('close');
            }
            
          } catch (error) {
            // Handle errors
          }
          
        }
        else
        {
            this.validation_fail =true;
        }

      },

    },
    computed: {
    formIsValid() {
      return (
        this.formData.description.trim() !== "" &&
        this.formData.files.length >0
      );
    },
  },
  };
  </script>
  
  <style scoped>
  /* Add your styling for the modal */
  .modal-overlay {
    /* Overlay styles */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1040;
  }
  
  .modal-container {
    /* Modal styles */
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1050;
  }
  
  .modal-header {
    /* Header styles */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-header h3 {
    margin: 0;
  }
  
  .modal-header button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  </style>
  