<template>
  <div v-if="isOpen" class="modal-overlay">
    <div class="modal-container">
      <div class="modal-header">
        <h3>Hire An Employee</h3>
        <button @click="closeModal" class="close-button">&times;</button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="employee">Select Employee</label>
            <select v-model="selectedEmployee" @change="updateEmployeeDetails" class="form-control">
              <option v-for="employee in employees_data" :key="employee.id" :value="employee">
                {{ employee.first_name }} {{ employee.last_name }}
              </option>
            </select>
          </div>
          <div v-if="selectedEmployee" class="employee-details">
            <span><strong>First Name:</strong> {{ selectedEmployee.first_name }}</span>
            <span><strong>Last Name:</strong> {{ selectedEmployee.last_name }}</span>
            <span><strong>SP Number:</strong> {{ selectedEmployee.SP_number }}</span>
            <span><strong>Email:</strong> {{ selectedEmployee.email }}</span>
            <span><strong>Phone Number:</strong> {{ selectedEmployee.phone_number }}</span>
          </div>
          <div class="form-group">
            <label for="position">Position</label>
            <input type="text" v-model="formData.position" id="position" class="form-control" />
          </div>
          <div class="form-group">
            <label for="hired_date">Hired Date</label>
            <input type="date" v-model="formData.hired_date" id="hired_date" class="form-control" />
          </div>
          <button type="submit" class="btn btn-primary w-full">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';

export default {
  data() {
    return {
      formData: {
        position: '',
        hired_date: '',
        token: '',
        employee_id: null,
      },
      selectedEmployee: null,
      employees_data: [],
    };
  },
  props: {
    isOpen: Boolean,
    title: String,
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    async fetch_employees() {
      try {
        const token = localStorage.getItem('token');
        this.formData.token = token;
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_available_employees`, this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.employees_data = response.data.data;
      } catch (error) {
        // Handle errors
        console.error(error);
      }
    },
    updateEmployeeDetails() {
      if (this.selectedEmployee) {
        this.formData.employee_id = this.selectedEmployee.id;
      }
    },
    async submitForm() {
      if (this.formIsValid) {
        const token = localStorage.getItem('token');
        this.formData.token = token;
        Swal.fire({
          title: 'Loading...',
          text: 'saving data.',
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          }
        });
        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/hired_employee`, this.formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log(response.data);

          if (response.data.status == 200) {
            Swal.fire({ title: response.data.message, icon: "success" });
            this.$emit('close');
          }

        } catch (error) {
          // Handle errors
          console.error(error);
        }

      } else {
        this.validation_fail = true;
      }
    },
  },
  computed: {
    formIsValid() {
      return (
        this.formData.position.trim() !== "" &&
        this.formData.hired_date.trim() !== "" &&
        this.formData.employee_id !== null // Ensure employee_id is set
      );
    },
  },
  mounted() {
    this.fetch_employees();
  },
};
</script>


  
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1040;
}

.modal-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%;
  z-index: 1050;
  animation: fadeIn 0.3s ease;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.5rem;
}

.modal-header .close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #999;
}

.modal-header .close-button:hover {
  color: #333;
}

.modal-body {
  padding: 10px 0;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.btn {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.btn-primary {
  background-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.employee-details {
  margin: 15px 0;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
}

.employee-details span {
  display: block;
  margin-bottom: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
