<template>
    <div class="flex flex-wrap">
      <div class="w-full px-4">
        
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
        >
        <div class="text-right mt-6">
                  <button @click="openModal"
                    class="bg-lightBlue-600 text-white text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none focus:outline-none mr-4 mb-1  ease-linear transition-all duration-150" 
                  >
                    Upload File
                  </button>   
        </div>
        
        <table>
            <thead>
                <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
                Description
            </th>
            <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
                Date Uploaded
            </th>
            <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
                Actions 
            </th>
            </thead>
            <tbody>
                <tr v-for="client_file in file_uploads" :key="client_file.id" class="border-b border-gray-300" >
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                {{ client_file.description }}
              </td>
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                {{  new Date(client_file.upload_date).toLocaleString() }}
              </td>
              <td class="px-6 align-middle">
                <button @click="getImageBlobFromS3(client_file.files,  getFileNameFromUrl(client_file.files))" class="bg-teal-200 text-black active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1  ease-linear transition-all duration-150">
                     Download
                </button>
              </td>
              
              
            </tr>
           
          </tbody>
        </table>
        </div>
        <upload-files-modal :is-open="isModalOpen" @close="closeModal"/>
      </div>
    </div>
  </template>
  <script>
   import UploadFilesModal from "@/components/Modal/UploadFilesModal.vue";
   import axios from 'axios';
  export default {
        components: {
            UploadFilesModal,
        },
        data()
        {
           return {
            isModalOpen: false,
            file_uploads:[]
           }
        },
        methods:{
            openModal() {
            this.isModalOpen = true;
            },
            closeModal() {
            this.isModalOpen = false;
            this.fetch_files();
            },
            getFileNameFromUrl(url) {
            const parts = url.split('/');
            return parts[parts.length - 1];
          },
            
            async fetch_files()
            {
              const token = localStorage.getItem('token');

              const params_data = {
                token: token,

              };

              try {
                const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_file_uploads`, params_data);
                this.file_uploads = response.data.data; 
               
              } catch (error) {
                console.error("Backend error:", error);
              }
            },
            async getImageBlobFromS3(url, fileName) {
                try {
                    const response = await axios.get(url, { responseType: 'blob' });
                    this.downloadBlob(response.data, fileName);
                } catch (error) {
                    console.error('Error fetching image:', error);
                }
            },
            downloadBlob(blob, fileName) {
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            }

        },
        mounted() {
          this.fetch_files();
        },
  };
  </script>
  