<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0 mt-4">
            <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>Sign In</small>
            </div>
            <span v-if="validation_fail" class="text-red-500 text-sm">Email and password are incorrect</span>
            <span v-if="statusfail" class="text-red-500 text-sm">Account is not yet active.</span>
            <form @submit.prevent="submitForm">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-email">Email</label>
                <input
                  v-model="formData.email"
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                />
              </div>
              

              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Password</label>
                <div class="relative">
                  <input
                    v-model="formData.password"
                    :type="showPassword ? 'text' : 'password'" 
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Password"
                  />
                  <br>
                  <button type="button" class="button mt-2" @click="togglePasswordVisibility">
                    <span class="icon is-small is-right">
                        <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                        Show Password
                    </span>
                  </button>
                  
                </div>
              </div>

              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">Remember me</span>
                </label>
              </div>

              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <router-link to="/auth/forgot_password" class="text-blueGray-200">
              <small>Forgot Password?</small>
            </router-link>
          </div>
          <div class="w-1/2 text-right">
            <router-link to="/auth/register" class="text-blueGray-200">
              <small>Create new account</small>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <otp-modal :token="token" :is-open="isModalOpen" @close="closeModal" />
  </div>
</template>

<script>
import axios from "axios";
import OtpModal from "@/components/Modal/OTPModal.vue";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';

export default {
  data() {
    return {
      isModalOpen: false,
      formData: {
        email: "",
        password: "",
      },
      validation_fail: false,
      statusfail: false,
      token: "",
      showPassword: false, // Track whether to show password or not
    };
  },
  components: {
    OtpModal,
  },
  computed: {
    formIsValid() {
      return (
        this.formData.email.trim() !== "" &&
        this.formData.password.trim() !== ""
      );
    },
  },
  methods: {
    async submitForm() {
      if (this.formIsValid) {
        // Show loading spinner
        const loadingSpinner = Swal.fire({
          title: 'Please wait...',
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/login_auth`, this.formData);

          if (response.data.status === 200) {
            this.token = response.data.token;
            this.isModalOpen = true;
          } else if (response.data.status === 406) {
            this.statusfail = true;
          } else {
            this.validation_fail = true;
          }
        } catch (error) {
          console.error('Backend error:', error);
          this.validation_fail = true;
        } finally {
          // Close loading spinner
          loadingSpinner.close();
        }
      } else {
        this.validation_fail = true;
      }
    },
    logout() {
      localStorage.setItem('token', ""); 
      localStorage.setItem('tokenExpiration', "");
    },
    closeModal() {
      this.isModalOpen = false;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword; // Toggle password visibility
    },
  },
  mounted() {
    this.logout();
  },
};
</script>
