<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            Employee List
          </h3>
        </div>
        <div class="text-center mt-6">
                <button @click="openModal"
                  class="bg-emerald-600 text-white active:bg-emerald-200 text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Add employees
                </button>
                
              </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Full Name
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
             Documents
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Control Number
            </th>
            <!-- <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >Password
          </th>

            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >Status
          </th> -->
           
          </tr>
           
        </thead>
        <tbody>
   
          <tr v-for="employee in employees" :key="employee.id">
           
            <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
              {{ employee.first_name }} {{ employee.last_name }}
            </td>
            <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
              <!-- Display other employee data as needed -->
            
              <div v-for="file_data in employee.files" :key="file_data">
                <button @click="openFileSwal(file_data, getFileNameFromUrl(file_data))">
                  {{getFileNameFromUrl(file_data)}}
                  
                </button>
              </div>
            </td>
            <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
              {{ employee.SP_number }}
            </td>
         
          </tr>
         
        </tbody>
      </table>
    </div>
    <employee-modal :is-open="isModalOpen" @close="closeModal" @refresh-table="refresh_emplyoees" />
    <time-sheet-modal :is-open-time-sheet="isTimeSheetOpen" :timesheet-data="timesheetData" @close_timesheet="closeTimesheet"  />
  </div>
</template>
<script>



import axios from "axios";
import TimeSheetModal from "@/components/Modal/TimeSheetModal.vue";
import EmployeeModal from "@/components/Modal/EmployeeModal.vue";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
export default {
  data() {
    return {
      isModalOpen: false,
      employees: [],
      isTimeSheetOpen: false,
      timesheetData:{
        FirstName:"", LastName:"",
      }
    }
  },
  components: {
    EmployeeModal,
    TimeSheetModal
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    getFileNameFromUrl(url) {
      // Extracts the file name from a URL
      const parts = url.split('/');
      return parts[parts.length - 1];
    },
    async fetchEmployees() {
      const token = localStorage.getItem('token');

      const params_data = {
        token: token,

      };

      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_employees`, params_data);
   
        this.employees = response.data; 
        
      } catch (error) {
        // Handle errors
        console.error("Backend error:", error);
      }
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    refresh_emplyoees() 
    {
      this.fetchEmployees();
    },
    openFileSwal(file_data, file_name) {

            Swal.fire({
              title: file_name,
              html: this.getHtmlContent(file_data, file_name),
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
            });
    },
    getHtmlContent(file_data, file_name) {
      if (this.isImage(file_name)) {
        // If it's an image, use <img> tag
        return `<img src="${file_data}" style="max-width: 100%; max-height: 100%;">`;
      } else if (this.isPdf(file_name)) {
        // If it's a PDF, use <embed> tag
        return `<embed src="${file_data}" type="application/pdf" width="100%" height="500px">`;
      }else if (this.isExcel(file_name)) {
        // If it's an Excel file, use <embed> tag with the appropriate MIME type
          const downloadLink = `<a href="${file_data}" ><u>Download Excel</u></a>`;
          return `Please Download The file to view:<br> ${downloadLink}`;
      } else {
        // For other file types, you can handle accordingly
        return "Unsupported file type";
      }
    },
    isImage(fileName) {
      const imageExtensions = [".png", ".jpg", ".jpeg", ".gif", ".bmp"];
      return imageExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
    },
    isPdf(fileName) {
      return fileName.toLowerCase().endsWith(".pdf");
    },
    isExcel(fileName) {
      // Check if the MIME type indicates an Excel file
      return fileName.toLowerCase().endsWith(".xlsx");
    },
    viewTimeSheet(firstname, lastname, id){
      this.timesheetData = {
            FirstName: firstname,
            LastName: lastname,
            id: id
          };

      this.isTimeSheetOpen = true;
    },
    closeTimesheet()
    {
      this.isTimeSheetOpen = false;
    },
    copyToClipboard(employee) {
      const textArea = document.createElement('textarea');
      textArea.value =employee.pw;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      employee.isCopied = true;
      setTimeout(() => {
        employee.isCopied = false;
      }, 300);
    },

  },
  mounted() {
    this.fetchEmployees();
  },
};
</script>
